<template>
  <a class="header-logo">
    <img src="~assets/img/logo.png"/>
  </a>
</template>

<script>
export default {
  name: 'logo',
}
</script>

<style lang="scss" scoped>
  .header-logo {
    line-height: 80px;
    img {
      margin-left:80px;
      vertical-align: middle;
    }
  }
</style>
