<template>
  <div class="header-wrapper">
    <logo/>
    <item/>
  </div>
</template>

<script>
import Logo from './Logo'
import Item from './Item'

export default {
  name: 'Navbar',
  components:{
    Logo,
    Item
  },
}
</script>
