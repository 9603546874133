<template>
  <div class="footer-copyright">
    ©复凌科技（上海）有限公司版权所有    ICP证：<a href="http://beian.miit.gov.cn/" style="color: inherit; text-decoration: none;" target="_blank">沪ICP备14024073号-1</a>
  </div>
</template>

<script>
export default {
  name: 'Copyright'
}
</script>

<style lang="scss" scoped>
.footer-copyright {
  background: #3d6083;
  font-size: 14px;
  font-family: Source Han Sans CN-Regular;
  text-align: center;
  color: rgba(255,255,255,0.60);
  line-height: 46px;
}
</style>