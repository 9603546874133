<template>
  <div style="height:100%;">
    <el-backtop :bottom="100">
      <svg-icon icon-class="up"/>
    </el-backtop>
    <navbar />
    <div class="app-wrapper">
      <app-main class="main-container"></app-main>
    </div>
    <footbar/>
  </div>
</template>
<style lang="scss" scoped>
/deep/.el-backtop {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(34,34,34,0.10);
}
</style>
<script>
import { Navbar, AppMain, Footbar } from './components'
export default {
  name: 'Layout',
  components: {
    Navbar,
    AppMain,
    Footbar
  }
}
</script>
