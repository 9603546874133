<template>
  <div class="footer-nav-wrapper">
    <div class="footer-nav-content real-content">
      <a class="footer-logo">
        <img src="~assets/img/logo2.png" width="200"/>
      </a>
      <div class="footer-nav">
        <el-descriptions class="footer-nav-submenus" title="复凌科技（上海）有限公司" :column="1">
          <el-descriptions-item label="TEL" label-class-name="label" content-class-name="text">021-55669701</el-descriptions-item>
          <el-descriptions-item label="邮箱" label-class-name="label" content-class-name="text">market@foriin.com</el-descriptions-item>
          <el-descriptions-item label="电话" label-class-name="label" content-class-name="text">
            021-55669701<br/>
            13817056053（肖经理）<br/>
            17317637956（吴经理）
          </el-descriptions-item>
        </el-descriptions>
        <el-row class="footer-nav-menus">
          <el-col :span="4">
            <dl>
              <dt><a href="/about">关于我们</a></dt>
              <dd><a href="/about?id=intro">公司简介</a></dd>
              <dd><a href="/about?id=process">发展历程</a></dd>
              <dd><a href="/about?id=honor">资质荣誉</a></dd>
              <dd><a href="/about?id=join">加入我们</a></dd>
            </dl>
          </el-col>
          <el-col :span="4">
            <dl>
              <dt><router-link :to="{path: '/product/device/pollutant'}">产品技术</router-link></dt>
              <dd><router-link :to="{path: '/product/device/pollutant'}">环境监测设备</router-link></dd>
              <dd><router-link :to="{path: '/product/zeebra'}">Zeebraᵀᴹ</router-link></dd>
              <dd><router-link :to="{path: '/product/platform'}">应用平台</router-link></dd>
            </dl>
          </el-col>
          <el-col :span="4">
            <dl>
              <dt><router-link to="/solution">解决方案</router-link></dt>
              <dd><router-link to="/solution/operate">工程投资建设&运营</router-link></dd>
              <dd><router-link to="/solution/declare">项目策划与申报</router-link></dd>
              <dd><router-link to="/solution/supervise">智慧监管系统</router-link></dd>
            </dl>
          </el-col>
          <el-col :span="4">
            <dl>
              <dt><router-link :to="{path: '/case'}">典型案例</router-link></dt>
              <dd><router-link :to="{path: '/case', query: {index: 'first'}}">大气环境</router-link></dd>
              <dd><router-link :to="{path: '/case', query: {index: 'second'}}">声环境</router-link></dd>
              <dd><router-link :to="{path: '/case', query: {index: 'third'}}">水环境</router-link></dd>
              <dd><router-link :to="{path: '/case', query: {index: 'fourth'}}">污水治理</router-link></dd>
              <dd><router-link :to="{path: '/case', query: {index: 'fifth'}}">生态保护</router-link></dd>
            </dl>
          </el-col>
          <el-col :span="4">
            <dl>
              <dt><router-link :to="{path: '/ecosystem'}">合作生态圈</router-link></dt>
              <dd style="font-weight: bold; margin-top: 58px;"><router-link :to="{path: '/contact'}">联系我们</router-link></dd>
            </dl>
          </el-col>
          <el-col :span="4">
            <img src="~assets/img/code.png"/>
          </el-col>
        </el-row>
      </div>      
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavItem'
}
</script>

<style lang="scss">
.footer-nav-wrapper {
  background: url('~assets/img/foot-bg.png');
  background-size: 100% 100%;  
  margin-top: 200px;
  height: 374px;
  padding-top: 32px;
  .footer-nav-content {
    .footer-nav {
      display: flex;
      &-submenus {
        width: 308px;
        height: 263px;
        border-right: 1px solid rgba(255,255,255,0.16);
        font-size: 16px;
        text-align: left;
        .el-descriptions {
          &__header {
            margin-bottom: 28px;
          }
          &__title {
            font-size: 20px;
            color: #fff;
          }
          &__body {
            background: none;
            color: #ffffff;
          }
          &-item__container {
            line-height: 30px;
          }
        }
        .label {
          width: 44px;
          margin-right: 14px;
        }
        .text {
          font-weight: 700;
        }
      }
      &-menus {
        margin-top: 32px;
        margin-left: 50px;
        width: calc(100% - 358px);
        color: #ffffff;
        font-size: 16px;
        line-height: 18px;
        dl {
          margin: 0;
          a {
            color: #fff;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        dt {          
          font-weight: 700;
          margin-bottom: 20px;
        }
        dd {
          margin-left: 0;
          margin-bottom: 20px;
          
        }
      }
    }
  }
}
</style>