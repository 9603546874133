<template>
  <div class="foot-wrapper">
    <nav-item/>
    <copyright/>
  </div>
</template>

<script>
import NavItem from './NavItem'
import Copyright from './Copyright'

export default {
  name: 'Footbar',
  components:{
    NavItem,
    Copyright
  },
}
</script>
