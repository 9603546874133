import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)


const Home = () => import('views/home')
const About = () => import('views/about')
const Device = () => import('views/product/device/index')
const Zeebra = () => import('views/product/zeebra')
const Platform = () => import('views/product/platform')
const Solution = () => import('views/solution/index')
const Case = () => import('views/case/index')
const Ecosystem = () => import('views/ecosystem')
const Contact = () => import('views/contact')

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: Home,
        name: '首页',
      },
      {
        path: '/about',
        component: About,
        name: '关于我们',
      },     
      {
        path: '/solution',
        component: Solution,
        name: '解决方案',
        children: [
          {
            path: '/solution/supervise',
            component: () => import('views/solution/views/Supervise'),
            name: '解决方案-智慧监管系统',
          },
          {
            path: '/solution/declare',
            component: () => import('views/solution/views/Declare'),
            name: '解决方案-项目策划与申报',
          },
          {
            path: '/solution/operate',
            component: () => import('views/solution/views/Operate'),
            name: '解决方案-工程投资建设&运营',
          }
        ]
      },
      {
        path: '/case',
        component: Case,
        name: '典型案例',
      },
      {
        path: '/ecosystem',
        component: Ecosystem,
        name: '合作生态圈',
      },
      {
        path: '/contact',
        component: Contact,
        name: '联系我们',
      }
    ]
  },
  {
    path: '/product',
    name: '产品技术',
    component: Layout,
    children: [
      {
        path: '/product/device',
        component: Device,
        name: '产品技术-环境监测设备',
        children: [
          {
            name: '大气环境-挥发性有机化合物光离子化在线监测系统',
            path: '/product/device/voc',
            component: () => import('views/product/device/views/Voc')
          },
          {
            name: '大气环境-微型环境空气质量监测系统',
            path: '/product/device/feco',
            component: () => import('views/product/device/views/Feco')
          },
          {
            name: '大气环境-扬尘噪声监测仪',
            path: '/product/device/raiseDust',
            component: () => import('views/product/device/views/RaiseDust')
          },
          {
            name: '新型污染物',
            path: '/product/device/pollutant',
            component: () => import('views/product/device/views/Pollutant')
          },
          {
            name: '水生态',
            path: '/product/device/waterEco',
            component: () => import('views/product/device/views/WaterEco')
          },
          {
            name: '水环境',
            path: '/product/device/waterEnv',
            component: () => import('views/product/device/views/WaterEnvironment')
          }
          
        ]
      },
      {
        path: '/product/zeebra',
        component: Zeebra,
        name: '产品技术-Zeebraᵀᴹ',
      },
      {
        path: '/product/platform',
        component: Platform,
        name: '产品技术-应用平台',
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
