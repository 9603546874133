<template>
  <div class="header-nav">
    <div class="header-nav-hover-line"></div>
    <ul class="header-nav-list">
      <li class="menu-item" name="/home">
        <a href="/home">首页</a>
      </li>
      <li class="menu-item" name="/about">
        <a href="/about">关于我们</a>
      </li>
      <li class="menu-item" name="/product">
        <a href="javascrip:void()">产品技术</a>
        <div class="nav-dropdown product" v-on:mouseleave="switchProductSubMenu(productSubmenu)">
          <ul class="submenu">
            <li class="submenu-item" data-key="device" v-on:mouseover="switchProductSubMenu('device')">
              环境监测设备<span class="el-icon-arrow-right"></span>
            </li>
            <li class="submenu-item" data-key="zeebra" v-on:mouseover="switchProductSubMenu('zeebra')">
              Zeebraᵀᴹ<span class="el-icon-arrow-right"></span>
            </li>
            <li class="submenu-item" data-key="platform" v-on:mouseover="switchProductSubMenu('platform')">
              应用平台<span class="el-icon-arrow-right"></span>
            </li>
          </ul>
          <div class="dropdown-container">
            <div class="dropdown-item" data-name="device">
              <el-row>
                <el-col :span="6">
                  <dl>
                    <dt><a href="/product/device/pollutant">新型污染物</a></dt>
                    <dd><a href="/product/device/pollutant">微塑料拉曼分析仪</a></dd>
                  </dl>
                </el-col>
                <el-col :span="6">
                  <dl>
                    <dt><a href="/product/device/waterEco">水生态</a></dt>
                    <dd><a href="/product/device/waterEco">水生态智能监测仪</a></dd>
                  </dl>
                </el-col>
                <el-col :span="6">
                  <dl>
                    <dt><a href="/product/device/waterEnv">水环境</a></dt>
                    <dd><a href="/product/device/waterEnv">水质多参数浮标监测系统</a></dd>
                  </dl>
                </el-col>
                <el-col :span="6">
                  <dl>
                    <dt><a href="/product/device/voc">大气环境</a></dt>
                    <dd><a href="/product/device/voc">挥发性有机化合物光离子化在线监测系统</a></dd>
                    <dd><a href="/product/device/feco">微型环境空气质量监测系统</a></dd>
                    <dd><a href="/product/device/raiseDust">扬尘噪声监测仪</a></dd>
                  </dl>
                </el-col>
              </el-row>
            </div>
            <div class="dropdown-item" data-name="zeebra">
              <dl>
                <dt><router-link to="/product/zeebra">了解更多></router-link></dt>
                <dd>Zeebraᵀᴹ是复凌软件架构的中间层，也是核心层</dd>
              </dl>
            </div>
            <div class="dropdown-item" data-name="platform">
              <dl>
                <dt><router-link to="/product/platform">了解更多></router-link></dt>
                <dd>一览无余，“指”点江山</dd>
              </dl>
            </div>
          </div>
        </div>
      </li>
      <li class="menu-item" name="/solution">
        <a href="javascrip:void()">解决方案</a>
        <div class="nav-dropdown solution" style="width: auto; right: auto;">
          <ul class="submenu" style="width: auto;">
            <li class="submenu-item" data-key="supervise">
              <a href="/solution/supervise">智慧监管系统</a>
            </li>
            <li class="submenu-item" data-key="declare">
              <a href="/solution/declare">项目策划与申报</a>
            </li>
            <li class="submenu-item" data-key="operate">
              <a href="/solution/operate">工程投资建设&运营</a>
            </li>
          </ul>
        </div>
      </li>
      <li class="menu-item" name="/case">
        <a href="/case">典型案例</a>
      </li>
      <li class="menu-item" name="/ecosystem">
        <a href="/ecosystem">合作生态圈</a>
      </li>
      <li class="menu-item" name="/contact">
        <a href="/contact">联系我们</a>
      </li>
    </ul>
  </div>
</template>
<script>
import $ from 'jquery'
export default {
  name: "item",
  computed: {
    activeMenu() {
      const route = this.$route
      const { path } = route
      if(path.indexOf('/product') != -1) {
        this.productSubmenu = path.split('/')[2]
        return '/product'
      }
      this.productSubmenu = 'device'
      if(path.indexOf('/solution') != -1) {
        this.solutionSubmenu = path.split('/')[2]
        return '/solution'
      }
      return path
    }
  },
  data() {
    return {
      productSubmenu: '',
      solutionSubmenu: ''
    }
  },
  mounted() {
    $(`.header-nav-list > .menu-item[name='${this.activeMenu}']`).addClass('is-active')
    this.initPosition()
    window.onresize = () => {
      this.initPosition()
    }
    let _this = this
    $(".header-nav-list .menu-item").on('mouseover', function() {
      this.classList.add('hover')
      _this.lineMove(this.firstChild)
    }).on('mouseleave', function() {
      _this.initPosition()
      this.classList.remove('hover')
    })
  },
  methods: {
    initPosition() {
      const target = document.querySelector(".header-nav-list .menu-item.is-active a")
      this.lineMove(target)
    },
    lineMove(target) {
      let dom = document.querySelector(".header-nav-hover-line")
      dom.style.left = target.offsetLeft + 'px'
      dom.style.width = target.offsetWidth + 'px'
    },
    switchProductSubMenu(activeMenu) {
      $('.nav-dropdown.product .submenu-item').removeClass('active')
      $(`.nav-dropdown.product .submenu-item[data-key='${activeMenu}']`).addClass('active')
      $(`.dropdown-container .dropdown-item`).hide()
      $(`.dropdown-container .dropdown-item[data-name='${activeMenu}']`).show()
    },
    switchSolutionSubMenu(activeMenu) {
      $('.nav-dropdown.solution .submenu-item').removeClass('active')
      $(`.nav-dropdown.solution .submenu-item[data-key='${activeMenu}']`).addClass('active')
    }
  },
  watch: {
    productSubmenu(val) {
      this.switchProductSubMenu(val)
    },    
    solutionSubmenu(val) {
      this.switchSolutionSubMenu(val)
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-nav {
    float: right;
    margin-right: 120px;
    line-height: 80px;
    .header-nav-hover-line {
      position: absolute;
      z-index: -1;
      bottom: 0;
      height: 4px;
      width: 0;
      background: #ffffff;
      border-radius: 2px;
      transition: all 1s;
    }
    .header-nav-list {
      display: flex;
      text-align: center;
      & > li {
        width:140px;
        &.hover {
          .nav-dropdown {
            display: block;
          }
        }
        & > a {
          font-size: 22px;
          font-family: Source Han Sans CN-Regular;
          color: rgba(#fff, 0.8);
          text-decoration: none;
          display: inline-block;
        }
        &:hover, &.is-active {
          & > a {
            font-weight: 700;
            color: #ffffff;
          }
        }
      }
    }
    .nav-dropdown {
      display: none;
      position: absolute;
      right: 20px;
      top: 80px;
      width: 1204px;
      height: 248px;
      background: rgba(#ffffff, .05);
      border-radius: 4px;
      backdrop-filter: blur(20px);
      text-align: left;
      
      .dropdown-container {
        display: inline-block;
        vertical-align: top;
        width: 960px;
        .dropdown-item {
          display: none;
        }
        dl {
          padding: 36px;
          margin: 0;
          dt {
            font-size: 18px;
            font-family: Source Han Sans CN-Medium;
            font-weight: 500;
            color: #ffffff;
            line-height: 18px;
            margin-bottom: 20px;
          }
          dd {
            opacity: 0.8;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular;
            font-weight: 400;
            white-space: normal;
            color: #ffffff;
            line-height: 20px;
            margin: 0;
            margin-bottom: 18px;
          }
          a {
            color: #fff;
            text-decoration: none;
            &:hover, &:focus {
              text-decoration: underline;
            }
          }
        }
      }  
    }
    .submenu {
      display: inline-block;
      width: 240px;
      height: 248px;
      background: rgba(#ffffff, .05);
      border-radius: 4px 0px 0px 4px;        
      font-size: 22px;
      color: #ffffff;
      line-height: 72px;
      cursor: pointer;
      padding: {
        left: 32px;
        top: 12px;
        right: 20px;
      }
      &-item {
        & > a {
          color: inherit;
          text-decoration: none;
        }
        span {
          float: right;
          line-height: inherit;
          font-weight: 600;
          color: #B6D6EE;
        }
        &.active, &:hover {
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          color: #48a4ff;
          span {
            color: inherit;
          }
        }
      }
    }
    .sub-menu {
      width: 260px;
      height: 248px;
      background: rgba(#ffffff, .1);
      border-radius: 4px 0px 0px 4px;
      text-align: left;
      font-size: 22px;
      line-height: 72px;
      padding: {
        left: 32px;
        top: 12px;
        right: 20px;
      }
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          font-family: Source Han Sans CN, Source Han Sans CN-Bold;
          font-weight: 700;
          color: #48a4ff;
        }
      }
    }
  }  
</style>
